.card {
  width: calc((90vw - 50px) / 3);
  height: calc((90vw - 50px) / 3);
  text-decoration: none;
  color: #000000;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 1fr;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  grid-row: 1;
  grid-column: 1;
  border-radius: 2%;
}

.card__title {
  font-size: 14px;
  line-height: 18px;
  font-family: "Montserrat";
  z-index: 2;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 100%;
  align-self: start;
  /* justify-self: center; */
  color: antiquewhite;
  margin: 0px;
}

.card__text {
  font-size: 12px;
  line-height: 17px;
  font-family: "Montserrat";
  z-index: 3;
  text-decoration: none;
  /* padding: 5px 10px; */
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  text-align: right;
  color: antiquewhite;
}

.card__text_hidden {
  display: none;
}

.card__words {
  width: 100%;
  height: 25%;
  min-height: 75px;
  background-color: rgba(44, 44, 44, 0.5);
  grid-row: 1;
  grid-column: 1;
  justify-self: right;
  align-self: end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}

.card__words:hover {
  background-color: rgba(245, 157, 102, 0.5);
}

@media (min-width: 1200px) {
  .card {
    width: calc((90vw - 75px) / 4);
    height: calc((90vw - 75px) / 4);
  }
}

@media (max-width: 850px)  {
  .card__title {
    font-size: 13px;
    line-height: 14px;
  }
  .card__text {
    font-size: 10px;
    line-height: 12px;
  }
}

@media (max-width: 550px) {
  .card {
    width: calc((90vw - 15px) / 2);
    height: calc((90vw - 15px) / 2);
  }
}
