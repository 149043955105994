
.small-pic__img {
  border-radius: 2%;
}

.small-pictures__grid_h {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  gap: 5px;
  max-width: 90%;
  margin: 0px auto 0px;
}

.small-pic__img_h {
  width: calc((90vw - 20px) / 5);
  height: calc(0.75*(90vw - 20px) / 5);
  object-fit: cover;

}


