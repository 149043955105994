.item {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.item__title {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  min-width: 20%;
  max-width: fit-content;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  color: #7b7b7b;
  align-self: center;
}

.item__title:hover {
  color: rgb(245, 152, 102);
}

.item__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.item__content-item {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  /* min-width: 20%; */
  margin: 0 0 0 10px;
  text-align: left;
  /* padding-top: 15px; */
  /* text-transform: uppercase; */
  text-decoration: none;
  color: #bbbbbb;
}
