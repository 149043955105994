.continents-link {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: stretch;
  text-decoration: none;
  color: #7b7b7b;
}

.continents-link:hover {
  color: rgb(245, 152, 102);
}

.continents-link__picture {
  justify-self: center;
  max-width: 70px;
  max-height: 70px;
  grid-row: 1;
  grid-column: 1;
  z-index: 2;
  background-color: #2c2c2c;
  transition: opacity 0.1s ease-in-out;
}

.continents-link__picture_L {
  max-width: 100px;
}

.continents-link__text {
  font-family: "Montserrat";
  font-style: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin: auto auto 0 auto;
  text-decoration: none;
}

@media (max-width: 950px) {
  .continents-link__text {
    font-size: 10px;
    line-height: 15px;
  }
}

@media (max-width: 750px) {
  .continents-link__picture {
    max-width: 35px;
    max-height: 35px;
  }
  .continents-link__picture_L {
    max-width: 70px;
  }
}

@media (max-width: 550px) {
  .continents-link__text {
    display: none
  }
}
