.trip-navigation {
  box-sizing: border-box;
  display: flex;
  width: 90%;
  /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr; */
  justify-content: space-between;
  margin: 50px auto;
}

.trip-navigation__link {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
  margin: 0;
  color: #7b7b7b;
}

.trip-navigation__link_left {
  justify-self: left;
}

.trip-navigation__link_right {
  justify-self: right;
}

.trip-navigation__link:hover {
  color: rgb(245, 152, 102);
}
