.form__button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: "Montserrat";
  background-color: darkgrey;
  width: 200px;
  height: 35px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  align-self: center;
  color: black;
  margin: 20px auto;
}

.form__button:hover {
  background-color: rgb(245, 152, 102);
}

.form__button_inactive {
  background-color: darkgrey;
  color: dimgrey;
}

.form__button_inactive:hover {
  background-color: darkgrey;
}

.form__button_danger:hover {
  background-color: rgb(202, 6, 6);
}
