.header {
  width: 100%;
  min-width: 290px;
  height: 25vh;
  min-height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header__words {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
}

.header__title {
  font-family: "Mak";
  font-style: Bold;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #f7f2e1;
  text-align: center;
}

.header__title_large {
  font-family: "Mak";
  font-style: Bold;
  font-size: 60px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #f7f2e1;
  text-align: center;
}

.header__subtitle {
  font-family: "Mak";
  font-style: Bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #f7f2e1;
  text-align: center;
  /* margin: 3vh 30vw 0; */
}

.header__subtitle_hidden {
  display: none;
}

@media (max-width: 700px) {
  .header__title {
    font-size: 40px;
  }
  .header__subtitle {
    font-size: 20px;
  }
  .header__title_large {
    font-size: 50px;
  }
}
