.statistics {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  margin: 30px auto;
}

.statistics__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statistics__number {
  font-family: "MAK";
  font-size: 39px;
  line-height: 46px;
  color: #7b7b7b;
  margin: 0;
}

.statistics__text {
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 22px;
  margin: 0 10px;
  color: #7b7b7b;
}

@media (max-width: 550px) {
  .statistics__text {
    font-size: 15px;
    line-height: 17px;
    }

    .statistics__number {
      font-size: 30px;
      line-height: 35px;
      color: #7b7b7b;
      margin: 0;
    }
    
}
