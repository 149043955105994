
.small-pic__img {
  border-radius: 2%;
}

.small-pictures__grid_v {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 5px;
  max-width: 90%;
  margin: 10px auto 0px;
}

.small-pic__img_v {
  width: calc((90vw - 30px) / 7);
  height: calc((90vw - 30px) / 5.25);
  object-fit: cover;

}

.small-pic {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 0px;
}
