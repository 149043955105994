.story-content {
  margin: 20px auto 20px auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.story-content__text {
  font-size: 20px;
  line-height: 114%;
  width: 90%;
  font-weight: normal;
  font-family: "Montserrat";
  text-decoration: none;
  color: #f7f2e1;
  margin: 10px auto 0;
}

.story-content__photo {
  /* max-width: 1000px; */
  width: 90%;
  margin: 20px auto;
  border-radius: 2%;
  align-self: center;
}

.story-content__video {
  width: 90%;
  margin: 20px auto;
  border-radius: 2%;

}

.vertical_photos_two {
  width: 90%;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  align-items: flex-start;
  /* max-width: 1200px; */
}

.vertical_photo_of_two {
  width: 49%;
  margin: 0;
  border-radius: 2%;
}

.vertical_photo {
  width: 70%;
}
.vertical_photos_three {
  width: 90%;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  /* max-width: 1200px; */
}

.vertical_photo_of_three {
  width: 31%;
}

.story-content__link {
  font-size: 20px;
  line-height: 114%;
  width: 90%;
  font-weight: normal;
  font-family: "Montserrat";
  text-decoration: none;
  /* color: #f7f2e1; */
  margin: 10px auto 0;
  color: rgb(245, 152, 102);
}
