.continents-menu {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  justify-items: center;
  width: 90%;
  margin: 30px auto 0;
}

@media (max-width: 750px) {
  .continents-menu {
    gap: 6px;
  }
}

@media (max-width: 550px) {
  .continents-menu {
    gap: 2px;
    width: 97%;
  }
}

