.footer {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  width: 90%;
  text-align: right;
  font-size: 10px;
  line-height: 114%;
  font-weight: normal;
  font-family: "Montserrat";
  text-decoration: none;
  color: #7b7b7b;
  margin: 10px auto 10px;
}
