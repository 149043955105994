.picitem {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-bottom: 10px;
}

.picitem__image {
  width: 30%;
}

.picitem__content {
  display: flex;
  flex-direction: column;
}

.picitem__detail {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 0 10px;
  text-align: left;
  text-decoration: none;
  color: #bbbbbb;
  text-decoration: none;
}
