.best-pictures {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.best-pictures__title {
  font-family: "Mak";
  font-style: Bold;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #f7f2e1;
  text-align: center;
  margin: 50px auto;
}

.best-pictures__grid_h {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  max-width: 90%;
  margin: 10px auto 0px;
}

.best-pic__img {
  border-radius: 2%;
}

.best-pic__img_h {
  width: calc((90vw - 10px) / 2);
  height: calc(0.75 * (90vw - 10px) / 2);
  object-fit: cover;

}

.best-pictures__grid_v {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  max-width: 90%;
  margin: 10px auto 0px;
}

.best-pic__img_v {
  width: calc((90vw - 20px) / 3);
  height: calc((90vw - 20px) / 2.25);
  object-fit: cover;

}

.best-pic {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.best-pic__text {
  font-size: 12px;
  line-height: 17px;
  font-family: "Montserrat";
  z-index: 3;
  text-decoration: none;
  padding: 5px 0;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  text-align: right;
  color: antiquewhite;
}

.best-pic__text:hover {
  color: rgb(245, 152, 102);
  text-decoration: none;
}

.best-pictures__shuffle {
  font-size: 12px;
  line-height: 17px;
  font-family: "Montserrat";
  text-decoration: none;
  /* padding: 5px 10px; */
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  text-align: right;
  color: antiquewhite;
}

.best-pictures__shuffle:hover {
  color: rgb(245, 152, 102);
}

.tag__links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  min-height: 50px;
  margin: 0 auto 10px auto;
}

.tag__link {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #7b7b7b;
  margin: auto 10px auto auto;
}

@media (min-width: 900px) {
  .best-pictures__grid_h {
    grid-template-columns: repeat(3, 1fr);
  }
  .best-pic__img_h {
    width: calc((90vw - 10px) / 3);
    height: calc(0.75 * (90vw - 50px) / 3);
  }
  .best-pictures__grid_v {
    grid-template-columns: repeat(4, 1fr);
  }

  .best-pic__img_v {
    width: calc((90vw - 30px) / 4);
    height: calc((90vw - 30px) / 3);
  }
}
