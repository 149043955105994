.admin__page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin__title {
  color: #7b7b7b;
  text-align: center;
  font-family: "Mak";
  font-style: Bold;
  font-size: 45px;
  text-transform: uppercase;
  margin: 75px auto 0;
  text-decoration: none;
}

.admin__table {
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
}
