.contacts-content {
    margin: 40px auto 40px auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .contacts-content__text {
    font-size: 20px;
    line-height: 114%;
    width: 90%;
    font-weight: normal;
    font-family: "Montserrat";
    text-decoration: none;
    color: #f7f2e1;
    margin: 10px auto 10px;
  }
  