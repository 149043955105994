.grid-section {
  display: flex;
  flex-direction: column;
}

.grid-section__title {
  font-family: "Mak";
  font-style: Bold;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: 3.5%;
  color: #7b7b7b;
  width: 100%;
  text-align: center;
  margin: 20px auto 0px;
}

.grid-section__title_hidden {
  display: none;
}

@media (max-width: 950px) {
  .grid-section__title {
    font-size: 40px;
    margin: 35px auto 25px;
  }
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 25px;
  max-width: 90%;
  margin: 30px auto 30px;
}

@media (min-width: 1200px) {
  .photo-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 550px) {
  .photo-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .grid-section__title {
    font-size: 30px;
    margin: 15px auto 15px;
  }

}
