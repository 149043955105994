.story-header {
  min-width: 100vw;
  height: 15vh;
  min-height: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.story-navigation {
  display: flex;
  align-content: space-between;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  background-color: rgba(44, 44, 44);
}

.story-navigation__link {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  min-width: 20%;
  text-align: center;
  padding-top: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: #7b7b7b;
}

.story-navigation__link:hover {
  color: rgb(245, 152, 102);
}

.story__title {
  color: rgb(245, 152, 102);
  text-align: center;
  font-family: "Mak";
  font-style: Bold;
  font-size: 45px;
  text-transform: uppercase;
  margin: 50px auto 0;
  /* margin: 5vh 25vw 0; */
}

.story__subtitle {
  font-family: "Montserrat";
  font-style: Bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 3%;
  color: rgb(245, 152, 102);
  margin: 20px auto 0;

  text-align: center;
}

.story__date {
  font-family: "Montserrat";
  font-style: Bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 3%;
  color: rgb(245, 152, 102);
  margin: 30px auto 0;
  text-align: center;
}

@media (max-width: 900px) {
  .story__title {
    font-size: 40px;
  }
  .story__subtitle {
    font-size: 25px;
  }
  .story__date {
    font-size: 25px;
  }
}

@media (max-width: 700px) {
  .story-navigation__link {
    font-size: 12px;
  }
}
