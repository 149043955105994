.error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2c2c2c;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error_off {
  display: none;
}

.error__message {
  font-family: "Montserrat";
  font-style: Bold;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #7b7b7b;
  margin: 10px auto 30px;

  text-align: center;
}

.error__link {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  min-width: 20%;
  text-align: center;
  padding-top: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: #7b7b7b;
  margin: 0;
}

.error__link:hover {
  color: rgb(245, 152, 102);
}
