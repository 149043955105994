@font-face {
  src: url(./MAK.woff) format("woff"), url(./MAK.eot) format("opentype"),
    url(./MAK.otf) format("otf");
  font-family: "MAK";
}

@font-face {
  src: url(./MAK-bold.eot) format("opentype"),
    url(./MAK-bold.woff) format("woff"), url(./MAK-bold.otf) format("otf");
  font-family: "MAK";
  font-weight: bold;
}

@font-face {
  src: url(./montserrat-regular.woff2) format("woff2"),
    url(./montserrat-regular.woff) format("woff"),
    url(./montserrat-regular.ttf) format("truetype"),
    url(./montserrat-regular.otf) format("otf");
  font-family: "Montserrat";
  font-weight: normal;
}
