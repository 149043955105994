.site-navigation__links {
  display: flex;
  align-content: space-around;
  width: 100%;
  min-height: 50px;
  background-color: rgba(44, 44, 44);
}

.site-navigation__link {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 20px;
  min-width: 20%;
  text-align: center;
  padding-top: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: #7b7b7b;
}

@media (max-width: 700px) {
  .site-navigation__link {
    font-size: 12px;
  }
}

.site-navigation__link:hover {
  /* color: rgb(245, 152, 102); */
  /* background-color: #2c2c2c; */
  color: rgb(245, 152, 102);
}
