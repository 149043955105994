.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}

.form__title {
  color: #7b7b7b;
  text-align: center;
  font-family: "Mak";
  font-style: Bold;
  font-size: 45px;
  text-transform: uppercase;
  margin: 75px auto 0;
}

.form__message {
  color: rgb(245, 152, 102);
  text-align: center;
  font-family: "Montserrat";
  font-style: Bold;
  font-size: 20px;
  text-transform: uppercase;
  margin: 10px auto auto 5%;
}

.form {
  display: flex;
  width: 100%;
  margin: 50px auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form__rowwrap {
  width: 90%;
  margin: 20px auto 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  column-gap: 15px;
}

.form__buttonrow {
  background-color: dimgray;
  padding: 5px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: space-evenly;
  width: 90%;
}

.form__preview {
  display: flex;
  width: 100%;
  justify-content: center;
}

.form__values-check {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #7b7b7b;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
}

.form__example {
  border: #7b7b7b solid 2px;
  margin: 0 0 0 0px;
}

.form__reference {
  color: #7b7b7b;
}
