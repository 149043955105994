.more {
  width: 100%;
  height: 80px;
  display: flex;
  margin: auto 0 20px;
  justify-content: center;

}

.more__button {
  width: 30%;
  height: 36px;
  /* margin: auto; */
  background-color: #7b7b7b;
  color: #2c2c2c;
  border: none;
  border-radius: 6px;
  padding: 0px;
  font-family: "Montserrat";
  font-style: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;

}
