.image-popup {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* animation-name: poppingUp;
    animation-duration: 0.5s; */
}

.image-popup_opened {
  display: flex;
  /* visibility: visible;
        opacity: 1; */
}

/* @keyframes poppingUp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  } */

.image-popup__wrap {
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.image-popup__image {
  width: 100%;
  max-height: 90%;
  flex-shrink: 0;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin: 10px auto;
  border-radius: 2%;
}

.image-popup__comment {
  font-size: 12px;
  line-height: 17px;
  font-family: "Montserrat";
  text-decoration: none;
  /* padding: 5px 10px; */
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  text-align: right;
  color: antiquewhite;
}

.image-popup__comment:hover {
  color: rgb(245, 152, 102);
}

@media screen and (max-width: 680px) {
  .image-popup__close {
    width: 20px;
    height: 20px;
    background-size: contain;
    margin-left: calc(100% - 20px);
  }
}
