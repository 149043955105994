.form__inputwrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  /* width: 100%; */
}

.form__addparam {
  margin: 0 3px 0 0;
  background-color: darkgrey;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 3px;
  box-sizing: border-box;
  box-shadow: none;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Montserrat";
}

.form__addparam:hover {
  background-color: rgb(245, 152, 102);
}

.form__buttonwrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.form__input-name {
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 22px;
  margin: 0 auto 5px 2px;
  color: #7b7b7b;
  text-align: center;
}

.form__input {
  height: 35px;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  margin: 0px;
  box-sizing: border-box;
  background-color: lightgray;
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 114%;
  padding-left: 5px;
}

.form__body {
  min-height: 250px;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  margin: 0px;
  box-sizing: border-box;
  background-color: lightgray;
  font-family: "Montserrat";
  font-size: 10px;
  line-height: 100%;
  padding-left: 5px;
  width: 90vw;
  vertical-align: top;
}

.form__error {
  font-family: "Montserrat";
  font-size: 10px;
  line-height: 22px;
  margin: 3px auto 5px 2px;
  color: #ec4545;
  text-align: left;
  display: flex;
  min-height: 22px;
}

.form__error_hidden {
  color: transparent;
}

.form__input_XXL {
  width: 600px;
  box-shadow: none;
}

.form__input_L {
  width: 300px;
  box-shadow: none;
}

.form__input_M {
  width: 200px;
  box-shadow: none;
}

.form__input_S {
  width: 120px;
  box-shadow: none;
}

.form__input_XS {
  width: 75px;
  box-shadow: none;
}

.hidden-element {
  display: none;
}

.form__input_blocked {
  background-color: darkgray;
}

@media (max-width: 900px) {
  .form__input_XL {
    width: 300px;
  }
  .form__input_L {
    width: 200px;
  }
  .form__input_M {
    width: 150px;
  }
  .form__input_S {
    width: 80px;
  }
}
